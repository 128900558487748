import React from "react";
import { BiLogoGmail } from "react-icons/bi";
import { FaTelegram, FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import { linkClick } from "../../utils/link-click";

const Footer = () => {
  const PA = "https://allvinhistory-bucket.s3.amazonaws.com/assets/admin";

  return (
    <footer className="bg-gradient-to-r from-gray-900  to-black text-white px-8 py-16">
      {/* Footer Container */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Main Footer Content */}
        <div className="grid gap-8 md:grid-cols-3">
          {/* Column 1 - Logo and Branding */}
          <div className="flex flex-col items-center md:items-start">
            <Link to="/" className="flex flex-col items-center md:items-start">
              <img
                src={`${PA}/web-2logo.png`}
                alt="D.C.V Logo"
                className="w-20 h-20  mb-4 rounded-full shadow-lg transition-transform transform hover:scale-105"
              />
              <span className="text-2xl font-bold uppercase">D.C.V</span>
            </Link>
            <p className="mt-4 text-center md:text-left text-gray-400 max-w-sm">
              Welcome to D.C.V (damagedcarvin.com), your main destination for
              importing cars from America! We offer comprehensive services and
              support to make your car importing experience seamless and
              successful.
            </p>
          </div>

          {/* Column 2 - Quick Links */}
          <div>
            <h3 className="text-lg font-semibold text-yellow-500">Quick Links</h3>
            <ul className="mt-4 space-y-2 text-gray-300">
              <li className="hover:text-white cursor-pointer">
                <Link to="/about" onClick={() => linkClick("/about")}>Our Company</Link>
              </li>
              <li className="hover:text-white cursor-pointer">
                <Link to="/contact" onClick={() => linkClick("/contact")}>Contact</Link>
              </li>
              <li className="hover:text-white cursor-pointer">
                <Link to="/dcv/terms" onClick={() => linkClick("/dcv/terms")}>Terms & Conditions</Link>
              </li>
              <li className="hover:text-white cursor-pointer">
                <Link to="/dcv/privacy" onClick={() => linkClick("/dcv/privacy")}>Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-yellow-500">Popular Makes Links</h3>
            <ul className="mt-4 space-y-2 text-gray-300">
              <li className="hover:text-white cursor-pointer">
                <Link to="/bmw?page=1" onClick={() => linkClick("/bmw?page=1")}>Bmw</Link>
              </li>
              <li className="hover:text-white cursor-pointer">
                <Link to="/mercedes-benz?page=1" onClick={() => linkClick("/mercedes-benz?page=1")} >Mercdes-Benz</Link>
              </li>
              <li className="hover:text-white cursor-pointer">
                <Link to="/toyota?page=1" onClick={() => linkClick("/toyota?page=1")}>Toyota</Link>
              </li>
              <li className="hover:text-white cursor-pointer">
                <Link to="/audi?page=1" onClick={() => linkClick("/audi?page=1")}>Audi</Link>
              </li>
              <li className="hover:text-white cursor-pointer">
                <Link to="/lexus?page=1" onClick={() => linkClick("/lexus?page=1")}>Lexus</Link>
              </li>
              <li className="hover:text-white cursor-pointer">
                <Link to="/hyundai?page=1" onClick={() => linkClick("/hyundai?page=1")}>Hyundai</Link>
              </li>

              <li className="hover:text-white cursor-pointer">
                <Link to="/jeep?page=1" onClick={() => linkClick("/jeep?page=1")}>Jeep</Link>
              </li>

            </ul>
          </div>

          {/* Column 3 - Contact & Social Media */}
          <div>
            <h3 className="text-lg font-semibold text-yellow-500">Contact Us</h3>
            <ul className="mt-4 space-y-2 text-gray-300">
              <li className="flex items-center">
                <BiLogoGmail className="mr-2" size="20" />
                damagedvarvin@gmail.com
              </li>
              <li className="flex items-center">
                <FaTelegram className="mr-2" size="20" />
                <a
                  href="https://t.me/damagedcarvininfo"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-white"
                >
                  @damagedcarvininfo
                </a>
              </li>
            </ul>
            {/* Social Media Icons */}
            <div className="mt-6 flex space-x-4">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors duration-300"
              >
                <FaFacebook size="30" />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors duration-300"
              >
                <FaTwitter size="30" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors duration-300"
              >
                <FaInstagram size="30" />
              </a>
              <a
                href="https://mail.google.com/mail/u/0/#inbox?compose=new"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors duration-300"
              >
                <BiLogoGmail size="30" />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Footer - Copyright */}
        <div className="mt-8 border-t border-gray-700 pt-4">
          <div className="text-center text-sm text-gray-500">
            © 2024 D.C.V. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
