import { makeModelRequest, publicRequest } from "./requestMethods";

const requestUtils = {
  _getMakes: async () => {
    try {
      const newProductdata = await makeModelRequest.get("/make-models");
      return newProductdata.data;
    } catch (err) {}
  },
  _getNewProduct: async () => {
    try {
      const newProductdata = await publicRequest.get("/public/random/product/dcv");
      return newProductdata.data;
    } catch (err) {}
  },
  _getRecentProduct: async () => {
    try {
      const newProductdata = await publicRequest.get("/public/recent/product/dcv");
      return newProductdata.data;
    } catch (err) {}
  },
  _getPopularProduct: async () => {
    try {
      const newProductdata = await publicRequest.get("/public/popular/product/dcv");
      return newProductdata.data;
    } catch (err) {}
  },
  _getNewestProduct: async () => {
    try {
      const newProductdata = await publicRequest.get("/public/newest/product/dcv");
      return newProductdata.data;
    } catch (err) {}
  },
  _getoldProduct: async () => {
    try {
      const newProductdata = await publicRequest.get("/public/old/product/dcv");
      return newProductdata.data;
    } catch (err) {}
  },

  _getSuggestProduct: async () => {
    try {
      const SuggestProductdata = await publicRequest.get("/public/random/product/dcv");
      return SuggestProductdata.data;
    } catch (err) {}
  },
  _getProductById: async (lot) => {
    try {
      const productById = await publicRequest.get("/public/product/dcv/" + lot);
      return productById.data;
    } catch (err) {
      return err;
    }
  },
  _getProductByVin: async (vin) => {
    try {
      const productByVin = await publicRequest.get("/public/get/dcv/" + vin);
      return productByVin.data;
    } catch (err) {
      return err;
    }
  },
  _getProductByQuery: async (query,vehiclePageCount) => {
    try {
      const productByQuery = await publicRequest.get(
        "/public/vehicle?searchVehicle=" +  query + "&page="+ vehiclePageCount
      );
      return productByQuery.data;
    } catch (err) {
      return err;
    }
  },
  _getProductsByUpdate: async (vehiclePageCount) => {
    try {
      const productByQuery = await publicRequest.get("/public/vehicle/dcv/?page=" + vehiclePageCount);
      return productByQuery.data;
    } catch (err) {
      return err;
    }
  },
};

export const {
  _getNewProduct,
  _getSuggestProduct,
  _getProductById,
  _getProductByVin,
  _getProductByQuery,
  _getProductsByUpdate,
  _getNewestProduct,
  _getPopularProduct,
  _getRecentProduct,
  _getoldProduct,
  _getMakes
} = requestUtils;
export default requestUtils;
