import React from 'react'
import { Link } from 'react-router-dom';
import { linkClick } from '../../utils/link-click';
import { pictureSrcUpdate } from '../../utils/imgsrc-utils';

const SimilarDataPage = ({ similarData }) => {
    return (
        <div className="mt-8">
            <span className="text-xl font-semibold mb-4 text-gray-700 p-2">Hot Auction Vehicles</span>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 ">
                {similarData?.map((product) => (
                    <div key={product?.id} className="bg-white p-4 rounded-md shadow-lg ">
                        <img src={pictureSrcUpdate(product)} alt={product?.name} className="w-full h-60 object-cover rounded-md mb-4" width="auto" height="60"/>
                        <h3 className="text-lg font-semibold mb-2 text-gray-800 whitespace-nowrap text-ellipsis overflow-hidden">{product?.name}</h3>
                        <p className="text-gray-600 mb-2 whitespace-nowrap text-ellipsis overflow-hidden">Primary Damage: {product?.primary_damage ? product?.primary_damage : "N/A"}</p>
                        <p className="text-gray-600 mb-2 ">Price: {product?.final_bid_formatted}</p>
                        <Link to={`/${product?.makes?.toLowerCase()}/${product?.vin}`}
                            onClick={(e) => {
                                e.preventDefault();
                                linkClick(`/${product?.makes?.toLowerCase()}/${product?.vin}`)
                            }} className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700 transition-colors duration-300">
                            View Details
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SimilarDataPage;
