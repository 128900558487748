import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import "./main.css"
import { ThemeProvider } from '@material-tailwind/react';
import MyContextProvider from './context/Contextprovider';
import { HelmetProvider } from 'react-helmet-async';

ReactDOM.hydrate(
  <BrowserRouter>
    <HelmetProvider>
      <ThemeProvider>
        <MyContextProvider>
          <App />
        </MyContextProvider>
      </ThemeProvider>
    </HelmetProvider>
  </BrowserRouter>
  , document.querySelector('#root'))





