import { Carousel } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { handleImageError, pictureSrcForProduct } from "../../utils/imgsrc-utils";

const CustomSingleSlider = ({ item, currentImage }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false); // Start with false



  useEffect(() => {
    if (typeof window !== "undefined") {
      // Check if window is available (browser environment)
      setIsMobile(window.innerWidth < 768);

      // Function to handle window resize
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Clean up event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  useEffect(() => {
    setCurrentImageIndex(currentImage);
  }, [currentImage]);

  const goToImage = (index) => {
    setCurrentImageIndex(index);
  };


 

  return (
    <div className="relative w-full  h-full md:h-[500px] overflow-hidden">
      <div
        className=" flex transition-transform duration-500 "
        style={{
          transform: isMobile ? `translateX(-${currentImageIndex * 100}%)` : `translateX(-${(currentImageIndex / item?.imgUrls?.length) * 100}%)`,
          width: isMobile ? `100%` : `${item?.imgUrls?.length * 100}%`,
        }}
      >
        {item?.imgUrls?.map((image, index) => (
          <img
            key={index}
            src={`${pictureSrcForProduct(item, image)} `}
            onError={handleImageError}
            alt={`slider ${index + 1} `}
            className="w-full h-full object-cover"
            height="auto"
            width="auto"
          />
        ))}
      </div>

      {/* Navigation dots */}
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {item?.imgUrls?.map((_, index) => (
          <button
            key={index}
            onClick={() => goToImage(index)}
            className={`w - 3 h - 3 rounded - full ${index === currentImageIndex ? "bg-gray-600" : "bg-gray-300"
              } `}
          />
        ))}
      </div>

      {/* Navigation arrows */}
      <button
        className="absolute top-1/2 left-2 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full"
        onClick={() =>
          setCurrentImageIndex(
            (prevIndex) =>
              (prevIndex - 1 + item?.imgUrls?.length) % item?.imgUrls?.length
          )
        }
      >
        <AiOutlineArrowLeft />
      </button>
      <button
        className="absolute top-1/2 right-2 transform -translate-y-1/2 p-2 bg-gray-800 text-white rounded-full"
        onClick={() =>
          setCurrentImageIndex(
            (prevIndex) => (prevIndex + 1) % item?.imgUrls?.length
          )
        }
      >
        <AiOutlineArrowRight />
      </button>
    </div>
  );
};

export default CustomSingleSlider;
