import { linkClick } from '../../utils/link-click';
import { handleImageError, pictureSrcUpdate } from '../../utils/imgsrc-utils';
import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const CustomCarousel = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsToShow = 4; // Number of items to show at once

    // Function to handle next slide
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + itemsToShow) % data.length);
    };

    // Function to handle previous slide
    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - itemsToShow + data.length) % data.length);
    };

    return (
        <div className="relative w-full max-w-screen-xl mx-auto">
            {/* Previous Button */}
            <button
                onClick={prevSlide}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 p-3 rounded-full shadow-lg text-white z-10 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Previous Slide"
            >
                <FaChevronLeft size={24} />
            </button>

            {/* Carousel Item Container */}
            <div className="overflow-hidden">
                <div
                    className="flex transition-transform duration-500"
                    style={{ transform: `translateX(-${(100 / itemsToShow) * currentIndex}%)` }}
                >
                    {data?.map((item) => (
                        <div
                            key={item.id}
                            className="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4"
                        >
                            <div className="rounded-lg overflow-hidden">
                                <img
                                    src={pictureSrcUpdate(item)}
                                    alt={`${item?.name}`}
                                    className="w-full h-auto object-cover"
                                    width={"auto"}
                                    height={"auto"}
                                    onError={handleImageError}
                                />
                            </div>
                            <div className="text-center mt-2">
                                <Link to={`/${item?.makes?.toLowerCase()}/${item?.vin}`} onClick={(e) => {
                                    e.preventDefault()
                                    linkClick(`/${item?.makes?.toLowerCase()}/${item?.vin}`)
                                }}>
                                    <p className="text-sm font-semibold text-blue-500  whitespace-nowrap text-ellipsis overflow-hidden">{item.name}</p>
                                    <p className="text-sm font-semibold text-white">{item.vin}</p>
                                </Link>
                                <p className="text-xs text-white">{item.final_bid_formatted || "$ 0.00"}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Next Button */}
            <button
                onClick={nextSlide}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 p-3 rounded-full shadow-lg text-white z-10 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Next Slide"
            >
                <FaChevronRight size={24} />
            </button>
        </div>
    );
};

export default CustomCarousel;
