import parse from 'html-react-parser';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AiFillCopy, AiOutlineDelete } from "react-icons/ai";
import { IoIosPricetag } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";


import { handleImageError, pictureSrcForProduct, pictureSrcUpdate } from "../../utils/imgsrc-utils";
import { publicRequest } from "../../utils/requestMethods";
import { _getPopularProduct } from "../../utils/requestUtils";

import CustomSingleSlider from "../../components/_customcarSlider/CustomSingleSlider";
import Breadcrumbs from '../../components/breadcrumbs/BreadCrumbs';
import { custom } from '../../components/customs/custom';
import PaymentModal from "../../components/modal/HidePayment";
import Productskeleton from "../../components/skeleton/Productskeleton";
import SimilarDataPage from '../../components/customs/similardata';
import useMyContext from "../../context/useMyContext";



const ProductCar = ({ data }) => {
  const location = useLocation();
  const params = useParams();
  const [swtichImage, setSwitchImage] = useState(0);
  const [singleProductData, setSingleProductData] = useState(data?.data);
  const [similarData, setSimilarData] = useState(data?.suggest);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { carId, updateData } = useMyContext();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    async function getProduct() {
      try {
        const productById = await publicRequest.get(`/public/get/dcv/` + params.vin);
        setSingleProductData(productById.data);
      } catch (err) {
        console.log("Error fetching product data");
        setError(true);
      }
    }

    getProduct();
  }, [params.vin]);


  useEffect(() => {
    if (isModalOpen) {
      updateData(singleProductData?.id);
    }
  }, [isModalOpen]);

  useEffect(() => {
    _getPopularProduct().then(data => setSimilarData(data)).catch(err => console.log("Error fetching similar products"));
  }, []);

  const handleSwitchImage = (index) => setSwitchImage(index);

  const handleCopyVin = async () => {
    if (singleProductData?.vin) {
      try {
        await navigator.clipboard.writeText(singleProductData?.vin);
        console.log('Text copied to clipboard successfully');
      } catch (error) {
        console.error('Error copying text to clipboard:', error);
      }
    }
  };

  const currentDate = new Date();

  // Extract the date components
  const year_date = currentDate.getFullYear();
  const month_date = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
  const day_date = String(currentDate.getDate()).padStart(2, '0');
  const formattedPrice = `${/^\d+(\.\d{1,2})?$/.test(singleProductData?.final_bid_formatted) ? singleProductData?.final_bid_formatted === "0.00" ? "111" : singleProductData?.final_bid_formatted : "999"}`;
  const result = singleProductData?.odometer_to_string?.match(/^(\d+)(.*)$/);
  const odometerValue = result ? `${result[1]}` : '9999';




  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${year_date}-${month_date}-${day_date}`;
  const ltdJson = {
    "@context": "https://schema.org",
    "@type": "Car",
    "url": `https://damagedcarvin.com/${params?.make?.toLowerCase()}/${params?.vin}`,
    "name": `${singleProductData?.name} | ${params?.vin} | damagedcarvin`,
    "description": `VIN: ✔️ ${params?.vin} | ${singleProductData?.name} |  Primary: ${singleProductData?.primary_damage} odometer: ${singleProductData?.odometer_to_string}   ${singleProductData?.engine ? `Engine : ${singleProductData?.engine}` : ""}  ${singleProductData?.secondary_damage ? `Secondary : ${singleProductData?.secondary_damage}` : ""} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.fuel_type ? `Fuel : ${singleProductData?.fuel_type}` : ""} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.start_code ? `Start Code : ${singleProductData?.start_code}` : ""} | Check the full history and more details now!`,
    "vehicleIdentificationNumber": `${params?.vin}`,
    "sku": `${params?.vin}`,
    "mpn": `${params?.vin}`,
    "mileageFromOdometer": {
      "@type": "QuantitativeValue",
      "value": odometerValue,
      "unitText": "MI",
      "unitCode": "SMI",
    },
    "brand": { "@type": "Brand", "name": `${singleProductData?.makes}` },
    "vehicleModelDate": `${singleProductData?.car_age}`,
    "model": `${singleProductData?.model}`,
    "itemCondition": "https://schema.org/UsedCondition",
    "offers":
    {
      "@type": "Offer",
      "url": `https://damagedcarvin.com/${params?.make?.toLowerCase()}/${params?.vin}`,
      "priceCurrency": "USD",
      "price": formattedPrice,
      "priceValidUntil": `${formattedDate}`,
      "availability": "https://schema.org/InStock",
      "seller":
      {
        "@type": "Organization",
        "name": "Progressive Casualty Insurance"
      }
    },
    "image": ` ${pictureSrcUpdate(singleProductData)}`
  }




  const ltdJsonList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Automobile",
        "item": "https://damagedcarvin.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": `${params?.make?.toLowerCase()}`,
        "item": `https://damagedcarvin.com/${params?.make?.toLowerCase()}?page=1`
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": `${singleProductData?.name}`,
        "item": `https://damagedcarvin.com/${params?.make?.toLowerCase()}/${params?.vin}`
      },

    ]
  }


  const crumbs = () => {
    return [
      { label: 'Home', path: '/' },
      { label: `${params?.make} `, path: `/${params?.make?.toLowerCase()}?page=1` },
      { label: `${params?.vin} `, path: `/${params?.make?.toLowerCase()}/${params?.vin}` },
    ];
  }


  return (<>

    <div className="ProductCar bg-gray-200 min-h-screen py-8 px-4 md:px-8 pt-20 md:pt-2">
      <div className='py-8'>
        <Breadcrumbs crumbs={crumbs()} />
      </div>


      {(!singleProductData?.vin && !error) && <Productskeleton />}
      {(!singleProductData?.vin && error) && (
        <div className="flex flex-col items-center justify-center h-screen">

          <span className="text-xl font-semibold">VIN: {params?.vin}</span>
          <span className="text-lg">Access to this VIN code is restricted or does not exist.</span>
        </div>
      )}
      {singleProductData?.vin && (
        <>
          <Helmet>
            <title>{`VIN ${params?.vin} | ${singleProductData?.name}| Free Vin Decoder`}</title>
            <meta name="title" content={`VIN ${params?.vin} | ${singleProductData?.name} | Free Vin Decoder`} />
            <meta name="description" content={`VIN: ✔️ ${singleProductData?.vin} | ${singleProductData?.name} |  Primary: ${singleProductData?.primary_damage} odometer: ${singleProductData?.odometer_to_string}   ${singleProductData?.engine ? `Engine : ${singleProductData?.engine}` : ""}  ${singleProductData?.secondary_damage ? `Secondary : ${singleProductData?.secondary_damage}` : ""} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.fuel_type ? `Fuel : ${singleProductData?.fuel_type}` : ""} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.start_code ? `Start Code : ${singleProductData?.start_code}` : ""} | Check the full history and more details now!`} />
            <link rel="alternate" hreflang="en" href={`https://damagedcarvin.com/${params?.make?.toLowerCase()}/${params?.vin}`} />
            <link rel="alternate" hreflang="x-default" href={`https://damagedcarvin.com/${params?.make?.toLowerCase()}/${params?.vin}`} />
            <link rel="canonical" href={`https://damagedcarvin.com/${params?.make?.toLowerCase()}/${params?.vin}`} />
            <script type="application/ld+json">
              {JSON.stringify(ltdJson)}
            </script>
            <script type="application/ld+json">
              {JSON.stringify(ltdJsonList)}
            </script>
          </Helmet>

          <div className="bg-gradient-to-r from-blue-50 to-white p-8 rounded-xl shadow-xl mb-8 transition-all transform hover:scale-105">
            <h1 className="text-3xl font-extrabold text-blue-800 mb-6">{singleProductData?.vin} - Free VIN Decoder</h1>
            <p className="text-gray-600 text-lg leading-relaxed mb-6">
              Get full information with the free VIN decoder for {singleProductData?.vin}.
            </p>
            <p className="text-gray-600 text-lg leading-relaxed mb-6">
              This vehicle, a {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model},
              offers essential insights, including its current condition,
              odometer reading, damage report, and auction details.
            </p>

          </div>
          <header className="flex justify-between items-center bg-gray-800 text-white shadow-md p-4 rounded-md mb-6">
            <span className="text-2xl font-bold">{singleProductData?.name}</span>
            <div className="flex items-center gap-2">
              <span className="text-lg">{singleProductData?.vin}</span>
              <AiFillCopy onClick={handleCopyVin} className="text-gray-400 hover:text-white cursor-pointer transition-colors duration-300" size={20} />
            </div>
          </header>




          <div className="flex flex-col md:flex-row gap-6">
            <div className="w-full md:w-2/3 bg-white p-6 rounded-md shadow-lg">
              <span className="text-xl font-semibold mb-4">Car Details</span>
              <div className="flex flex-col gap-3">
                <div className="flex justify-between border-b pb-2">
                  <span className="font-medium">VIN</span>
                  <span>{singleProductData?.vin}</span>
                </div>
                <div className="flex justify-between border-b pb-2">
                  <span className="font-medium">Odometer</span>
                  <span>{singleProductData?.odometer ? `${singleProductData?.odometer} mi` : singleProductData?.odometer_to_string}</span>
                </div>
                {singleProductData?.body_style && custom.span("Body Style", singleProductData?.body_style)}
                {singleProductData?.primary_damage && custom.span("Primary Damage", singleProductData?.primary_damage)}
                {singleProductData?.secondary_damage && custom.span("Secondary Damage", singleProductData?.secondary_damage)}
                {singleProductData?.key_type && custom.span("Key", singleProductData?.key_type?.toUpperCase())}
                {singleProductData?.engine && custom.span("Engine", singleProductData?.engine)}
                {singleProductData?.fuel_type && custom.span("Fuel", singleProductData?.fuel_type)}
                {singleProductData?.start_code && custom.span("Start Code", singleProductData?.start_code)}
                {singleProductData?.makes && custom.span("Make", singleProductData?.makes)}
                {singleProductData?.model && custom.span("Model", singleProductData?.model)}
                {singleProductData?.drive_type && custom.span("Drive", singleProductData?.drive_type)}
                {singleProductData?.cylinders && custom.span("Cylinders", singleProductData?.cylinders)}
              </div>
            </div>




            <div className="w-full md:w-1/3 bg-white p-6 rounded-md shadow-lg ">
              <span className="text-xl font-semibold mb-4">Additional Information</span>
              <div className="flex items-center gap-3 mb-4 p-2 bg-red-800 rounded-md cursor-pointer text-white" onClick={openModal}>
                <AiOutlineDelete size={24} />
                <span className="text-lg">Hide Car Information</span>
              </div>
              <div className="flex justify-between border-b pb-2">
                <span className="font-medium"><IoIosPricetag size={20} /> Lot</span>
                <span>{singleProductData?.lot}</span>
              </div>
              <div className="flex justify-between pt-2">
                <span className="font-medium">Final Price</span>
                <span> ${singleProductData?.final_bid_formatted === "0" || !singleProductData?.final_bid_formatted || singleProductData?.final_bid_formatted === "---" ? "0.00" : singleProductData?.final_bid_formatted}</span>
              </div>
            </div>
          </div>

          <div className="bg-gradient-to-r from-blue-50 to-white p-8 mt-6 rounded-xl shadow-xl mb-2 transition-all transform hover:scale-105">
            <h2 className="text-3xl font-extrabold text-blue-800 mb-6 ">{singleProductData?.vin} - Lot {singleProductData?.lot} - {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} Auction Info</h2>
            <p className="text-gray-700 mb-4">
              This vehicle was featured in auction lot {singleProductData?.lot},
              where it garnered significant interest due to its condition and specifications.
            </p>
            <p className="text-gray-700 mb-4">
              The final auction price was {singleProductData?.final_bid_formatted},
              reflecting the market's valuation based on the vehicle's current state and potential for repair.
            </p>
          </div>

          <div className="mt-6 flex flex-col md:flex-row gap-6">
            {/* Main Image Slider */}
            <div className="flex-1">
              <div className="relative w-full aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg">
                <CustomSingleSlider item={singleProductData} currentImage={swtichImage} className="w-full h-full object-cover" />
              </div>
            </div>

            {/* Additional Images */}
            <div className="flex-1 overflow-auto p-2 bg-white rounded-lg shadow-lg  h-[310px] sm:h-[500px] ">
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {singleProductData?.imgUrls?.map((item, index) => (
                  <img
                    key={index}
                    onClick={() => handleSwitchImage(index)}
                    className="w-full h-32 object-cover border rounded-md shadow-md cursor-pointer transition-transform duration-300 transform hover:scale-105"
                    alt={`${singleProductData?.vin} ${singleProductData?.name} no.${index + 1}`}
                    onError={handleImageError}
                    src={pictureSrcForProduct(singleProductData, item)}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="bg-gradient-to-r from-blue-50 to-white p-8 mt-6 rounded-xl shadow-xl mb-2 transition-all transform hover:scale-105">
            <h2 className="text-3xl font-extrabold text-blue-800 mb-6">{singleProductData?.vin} - {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} Vehicle Details</h2>
            <p className="text-gray-700 mb-4">
              This {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} is equipped with a {singleProductData?.engine ?? "Unknown"} gas engine,
              providing reliable performance with a {singleProductData?.drive_type ?? "Unknown"} drive system.
            </p>
            <p className='text-gray-700 mb-4'>
              The current odometer reading stands at {singleProductData?.odometer_to_string ?? "Unknown"} miles, giving you an accurate idea of the vehicle's mileage.
            </p>
            <p className='text-gray-700 mb-4'>
              It has suffered {singleProductData?.primary_damage ?? "Unknown"} damage,  {singleProductData?.secondary_damage ? `and also experienced secondary damage in the form of ${singleProductData?.secondary_damage}` : "with no secondary damage reported."}.
            </p>
            <p className="text-gray-700 mb-4">
              Despite the damage, the vehicle {singleProductData?.start_code == "run and drive" ? ` is marked as "run and drive," meaning it remains operational and ready for use.` : "is not marked as operational, indicating it may require repairs before being driven."} </p>
            <p className="text-gray-700 mb-4">The key status for this car is {singleProductData?.key_type ?? "Unknown"}, adding to the overall value and convenience of the vehicle</p>
          </div>

          {singleProductData?.text_generation_dcv && (
            <div className="mt-6 p-4 bg-white rounded-md shadow-lg">
              <span className="text-xl font-semibold mb-2">Description</span>
              <p>{parse(singleProductData?.text_generation_dcv)}</p>
            </div>
          )}
        </>
      )}
      {isModalOpen && <PaymentModal carId={singleProductData?.id} closeModal={closeModal} />}

      {similarData?.length > 0 && (
        <SimilarDataPage similarData={similarData} />
      )}



      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6 py-2">
        {/* Left side - VIN Decoder Results */}
        <div className="bg-white p-6 rounded-md shadow-lg">
          <h3 className="text-xl font-bold text-gray-900 mb-4">VIN Decoder Results for {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} ({singleProductData?.vin})</h3>
          <p className="text-gray-700 mb-4">
            The VIN decoder results for this {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} provide a complete overview of the vehicle's specifications.
            With {singleProductData?.odometer_to_string} miles recorded, and {singleProductData?.primary_damage} listed as the main damage, the vehicle's history is clear.
          </p>
          <p className="text-gray-700 mb-4">
            For buyers or enthusiasts, this  {singleProductData?.makes} {singleProductData?.model} represents an opportunity to acquire a car with known damage and a documented history, making it easier to estimate repair costs and resale value.
          </p>
        </div>

        {/* Right side - Auction and Lot Information */}
        <div className="bg-white p-6 rounded-md shadow-lg">
          <h3 className="text-xl font-bold text-gray-900 mb-4">{singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} - VIN {singleProductData?.vin} - Auction and Lot Information</h3>
          <p className="text-gray-700 mb-4">
            At auction, this {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} was listed under lot number {singleProductData?.lot} and attracted attention due to its {singleProductData?.primary_damage} . The vehicle sold for ${singleProductData?.final_bid_formatted}, reflecting the condition, mileage, and repairs needed.
          </p>
          <p className="text-gray-700 mb-4">
            {
              singleProductData?.secondary_damage ?
                `Secondary damage is also reported as ${singleProductData?.secondary_damage}, which may have influenced the final price.`
                : "No secondary damage was reported, keeping the price reflective of the primary damage only."
            }
          </p>
        </div>
      </div>

    </div>
  </>

  );
};

export default ProductCar;
