import React from 'react'

const Productskeleton = () => {
  return (
    <div className="ProductCar bg-gray-200 min-h-screen py-8 px-4 md:px-8 pt-20 md:pt-2">
      <div className="flex flex-col gap-6 animate-pulse">
        {/* Skeleton Header */}
        <div className="bg-gray-300 h-8 w-1/3 rounded-md"></div>
        <div className="bg-gray-300 h-6 w-1/4 rounded-md"></div>

        {/* Skeleton Car Details */}
        <div className="bg-white p-6 rounded-md shadow-lg space-y-4">
          <div className="bg-gray-300 h-6 w-full rounded-md"></div>
          <div className="bg-gray-300 h-6 w-2/3 rounded-md"></div>
          <div className="bg-gray-300 h-6 w-1/2 rounded-md"></div>
        </div>

        {/* Skeleton Images */}
        <div className="flex gap-4">
          <div className="bg-gray-300 h-32 w-1/3 rounded-md"></div>
          <div className="bg-gray-300 h-32 w-1/3 rounded-md"></div>
          <div className="bg-gray-300 h-32 w-1/3 rounded-md"></div>
        </div>
      </div>
    </div>


  )
}

export default Productskeleton
