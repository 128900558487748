import React from 'react';
import ContentLoader from 'react-content-loader';

const AnimatedSkeleton = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={400}
    viewBox="0 0 100% 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="5" ry="5" width="80%" height="150" />
    <rect x="0" y="170" rx="3" ry="3" width="80%" height="10" />
    <rect x="0" y="190" rx="3" ry="3" width="60%" height="10" />
    <rect x="0" y="210" rx="3" ry="3" width="60%" height="20" />
  </ContentLoader>
);

export default AnimatedSkeleton;