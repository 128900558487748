import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../components/pagination/Pagination';
import Skeleton from 'react-loading-skeleton'; // Add this for skeleton loading
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton CSS
import { publicRequest } from '../../utils/requestMethods';
import { handleImageError, pictureSrcUpdate } from '../../utils/imgsrc-utils';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../components/breadcrumbs/BreadCrumbs';
import { linkClick } from '../../utils/link-click';

const ProductList = ({ data }) => {
    const navigate = useNavigate();
    const params = useParams();
    const { make } = params;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageQueryParam = queryParams.get('page');
    const [vehicleProducts, setVehicleProducts] = useState(data || []);
    const [vehiclePageCount, setVehiclePageCount] = useState(parseInt(pageQueryParam) || 1);
    const [vehicleProductsLoading, setVehicleProductsLoading] = useState(true);
    const [vehiclePageLoading, setVehiclePageLoading] = useState(false);
    const [vehiclePagelength, setVehiclePagelength] = useState(null);
    const [error, setError] = useState(false);
    const [datalength, setDataLength] = useState(null);
    const { pathname } = useLocation();

    // Set query params if not present
    useEffect(() => {
        if (!pageQueryParam || pageQueryParam === "null") {
            navigate(`${location.pathname}?page=1`);
        }
    }, [location, pageQueryParam]);

    // Scroll to top on route change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // Fetch products based on page count
    useEffect(() => {
        async function getProducts() {
            try {
                setVehicleProductsLoading(true);
                const response = await publicRequest.get(`/public/get/dcv/product/make/${make}?page=${vehiclePageCount}`);
                const data = response?.data;
                setVehicleProducts(data);
                setVehicleProductsLoading(false);
            } catch (error) {
                setError(true);
            }
        }
        getProducts();
    }, [vehiclePageCount]);

    const handlePageChange = (page) => {
        setVehiclePageCount(page);
        navigate(`${location.pathname}?page=${page}`);
    };
    const crumbs = () => {
        return [
            { label: 'Home', path: '/' },
            { label: `${make} `, path: `/${make.toLowerCase()}?page=1` },
        ];

    }

    return (
        <div className="container mx-auto px-4 py-8 ">

            <div className="bread-crumbs-list  items-start pt-12 md:pt-0  border-b-2 border-b-[#d1d5db]   md:text-base ">
                <Breadcrumbs crumbs={crumbs()} />
            </div>

            <div className="flex items-center justify-between pt-8 py-4 ">
                <span className=" bg-red-500 text-white text-md font-bold px-3 py-1 rounded-full shadow-lg transform -translate-y-1/2">
                    Products for {make}
                </span>
                <span className="bg-green-500 text-white px-3 py-1 rounded-full shadow-lg transform -translate-y-1/2 cursor-pointer">
                    Archived
                </span>
            </div>
            {vehicleProductsLoading ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {/* Skeleton loaders */}
                    {Array(12).fill().map((_, index) => (
                        <div key={index} className="p-4 border rounded-lg">
                            <Skeleton height={200} />
                            <Skeleton height={20} width="80%" className="my-2" />
                            <Skeleton height={20} width="50%" />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-8">
                    {/* Product Cards */}
                    {vehicleProducts?.map((product) => (
                        <div
                            key={product.id}
                            className="relative group bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:scale-105 overflow-hidden border border-gray-200"
                        >
                            {/* Product Image */}
                            <div className="relative">
                                <img
                                    src={pictureSrcUpdate(product)}
                                    alt={product.name}
                                    className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-110"
                                    width={'auto'}
                                    height={"auto"}
                                    onError={handleImageError}
                                />
                                {/* Badge for Car Age */}
                                <div className="absolute top-2 right-2 bg-yellow-500 text-white text-xs font-semibold px-2 py-1 rounded-full">
                                    {product.car_age} years
                                </div>
                            </div>

                            {/* Product Info */}
                            <div className="p-4 whitespace-nowrap text-ellipsis overflow-hidden">
                                <Link to={`/${product?.makes?.toLowerCase()}/${product?.vin}`} className=" z-[55]">
                                    <p className=' block text-lg z-[55] text-blue-600 font-bold hover:underline '> {product?.name}</p>
                                </Link>
                                <p className="text-gray-500 text-sm mt-1  z-50 cursor-pointer">
                                    {product?.makes} {product?.model} {product?.car_age}
                                </p>
                                <p>{product?.vin}</p>
                                <div className="mt-3 flex items-center justify-between ">
                                    <p className="text-lg font-semibold text-gray-800 z-50 opacity-0 ">${product.final_bid_formatted}</p>
                                    <Link 
                                    to={`/${product?.makes?.toLowerCase()}/${product?.vin}`}
                                     onClick={(e) => {
                                        e.preventDefault();
                                        linkClick(`/${product?.makes?.toLowerCase()}/${product?.vin}`)}} className="block text-lg z-[30] text-blue-600 font-bold hover:underline">
                                        <button className="bg-blue-500 text-white py-1 px-3 rounded-lg z-[30] hover:bg-blue-600 transition duration-300">
                                            Details
                                        </button>
                                    </Link>
                                </div>
                            </div>

                            {/* Hover Effect for More Details */}
                            <div className="absolute top-1/3  inset-0 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-75 transition-opacity duration-300 ">

                                <div className="absolute bottom-4 left-4 text-white  bg-black rounded-lg p-2">
                                    <p className="text-sm">Final Bid: ${product.final_bid_formatted}</p>
                                    <p className="text-xs">VIN: {product.vin}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Error Handling */}
            {error && <div className="text-red-600">Failed to load products. Please try again.</div>}

            {/* Pagination Component */}
            <div className="mt-8">
                <Pagination currentPage={vehiclePageCount} totalPages={50} params={{ make }} />
            </div>
        </div>
    );
};

export default ProductList;
