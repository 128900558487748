import React, { useEffect, useRef, useState } from "react";
import { BiX, BiMenu, BiSolidHelpCircle } from "react-icons/bi"; // Import the required icons
import { MdOutlineWorkOutline, MdOutlineContactPhone } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { AiFillCloseCircle, AiFillHome } from "react-icons/ai";
import { HiOutlineHomeModern } from "react-icons/hi2";
import __useClickOutside from "../_useOutsideref/_useOutsideClick";
import { linkClick } from "../../utils/link-click";
import { Menu, MenuHandler, MenuList, MenuItem } from "@material-tailwind/react";

const Topbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isCarHistoryOpen, setIsCarHistoryOpen] = useState(false);
  const [isSellTipsOpen, setIsSellTipsOpen] = useState(false);

  const outsideRef = useRef();
  const outsideMenuRef = useRef();
  const PA = "https://allvinhistory-bucket.s3.amazonaws.com/assets/admin"
  const handleHover = () => {
    setIsDropdownOpen(true);
  };

  const handleLeave = () => {
    setIsDropdownOpen(false);
  };

  const toggleMenu = () => {
    setShowMenu(true);
  };
  __useClickOutside(outsideRef, () => setIsDropdownOpen(false));
  __useClickOutside(outsideMenuRef, () => setShowMenu(false));
  useEffect(() => {
    // Function to check window dimensions and close the menu if it's open and window size is too big
    const handleWindowResize = () => {
      if (window.innerWidth >= 640) {
        setShowMenu(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      {/* Mobile View */}
      <div className="xl:hidden w-full fixed z-[53]  h-[70px] bg-gradient-to-r from-gray-900 to-black shadow-lg">
        <button
          className="fixed top-4 left-5 text-white p-2 bg-gray-800 rounded-full focus:outline-none transition-transform transform hover:scale-105"
          onClick={toggleMenu}
        >
          {showMenu ? <BiX className="h-6 w-6" /> : <BiMenu className="h-6 w-6" />}
        </button>
      </div>

      {/* Desktop View */}
      <div className="hidden w-full z-[55] top-0 bg-gradient-to-r from-gray-800 to-black text-white xl:block shadow-lg">
        <div className="flex justify-center items-center w-full h-[70px] px-4">
          <div className="flex items-center gap-5">
            {/* Home Link */}
            <Link to="/" onClick={() => linkClick('/')} className="flex items-center gap-2 cursor-pointer text-white hover:text-gray-300 transition-colors">
              <HiOutlineHomeModern className="w-5 h-5" />
              <span className="text-sm font-semibold">Home</span>
            </Link>

            <Menu>
              <MenuHandler>
                <button className="flex items-center gap-2 text-white hover:text-gray-300 transition-colors">
                  <span className="text-sm font-semibold">Manufacturers from U.S.A</span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </MenuHandler>
              <MenuList className="bg-white rounded-md shadow-lg hidden md:block">
                <MenuItem>
                  <Link to="/bmw?page=1" onClick={() => linkClick('/bmw?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">BMW</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/lexus?page=1" onClick={() => linkClick('/lexus?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Lexus</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/audi?page=1" onClick={() => linkClick('/audi?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Audi</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/mercedes-benz?page=1" onClick={() => linkClick('/mercedes-benz?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Mercedes-Benz</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/tesla?page=1" onClick={() => linkClick('/tesla?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Tesla</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/ford?page=1" onClick={() => linkClick('/ford?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Ford</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/honda?page=1" onClick={() => linkClick('/honda?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Honda</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/toyota?page=1" onClick={() => linkClick('/toyota?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Toyota</Link>
                </MenuItem>
              </MenuList>
            </Menu>

            {/* Company Dropdown */}
            <Link to="/about" onClick={() => linkClick('/about')} className="flex items-center gap-2 cursor-pointer text-white hover:text-gray-300 transition-colors">
              <MdOutlineWorkOutline className="w-5 h-5" />
              <span className="text-sm font-semibold">Our Company</span></Link>



            {/* Contact Link */}
            <Link to="/contact" onClick={() => linkClick('/contact')} className="flex items-center gap-2 cursor-pointer text-white hover:text-gray-300 transition-colors">
              <MdOutlineContactPhone className="w-5 h-5" />
              <span className="text-sm font-semibold">Contact</span>
            </Link>
          </div>

        </div>
      </div>

      {/* Off-canvas Menu */}
      {showMenu && (
        <div className="fixed top-0 right-0 w-full h-full bg-gray-800 bg-opacity-75 z-[54]">
          <div ref={outsideMenuRef} className="bg-gray-900 w-[250px] h-full p-6 shadow-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="text-white">
                <img className="w-[50px] h-[50px] rounded-full shadow-md" src={`${PA}/web-2logoDark.png`} alt="web2-logo" />
                <span className="text-lg font-semibold">D.C.V</span>
              </div>
              <AiFillCloseCircle className="text-white w-[25px] h-[25px] cursor-pointer" onClick={() => setShowMenu(false)} />
            </div>
            <ul className="text-white space-y-4">
              <Link to="/" onClick={() => linkClick('/')} className="flex items-center gap-2 hover:text-gray-400">
                <HiOutlineHomeModern className="w-5 h-5" />
                Home
              </Link>
              <Menu>
                <MenuHandler>
                  <button className="flex items-center gap-2 text-white hover:text-gray-300 transition-colors">
                    <span className="text-sm font-semibold">Manufacturers from U.S.A</span>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                </MenuHandler>
                <MenuList className="bg-gray-200 rounded-md shadow-lg mt-2 w-48 max-h-[50vh] overflow-y-auto  ">
                  <MenuItem>
                    <Link to="/bmw?page=1" onClick={() => linkClick('/bmw?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">BMW</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/lexus?page=1" onClick={() => linkClick('/lexus?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Lexus</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/audi?page=1" onClick={() => linkClick('/audi?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Audi</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/mercedes-benz?page=1" onClick={() => linkClick('/mercedes-benz?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Mercedes-Benz</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/tesla?page=1" onClick={() => linkClick('/tesla?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Tesla</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/ford?page=1" onClick={() => linkClick('/ford?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Ford</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/honda?page=1" onClick={() => linkClick('/honda?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Honda</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/toyota?page=1" onClick={() => linkClick('/toyota?page=1')} className="text-gray-700 block px-4 py-2 hover:bg-gray-100">Toyota</Link>
                  </MenuItem>
                </MenuList>
              </Menu>
              <Link to="/about" onClick={() => linkClick('/about')} className="flex items-center gap-2 hover:text-gray-400">
                <MdOutlineWorkOutline className="w-5 h-5" />
                Our Company
              </Link>
              <Link to="/contact" onClick={() => linkClick('/contact')} className="flex items-center gap-2 hover:text-gray-400">
                <MdOutlineContactPhone className="w-5 h-5" />
                Contact
              </Link>
              {/* Add more menu items as needed */}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Topbar;
