import React, { useEffect, useState } from 'react';
import { IoIosClose, IoIosCheckmarkCircle } from 'react-icons/io';
import './Paymodal.css'; // Import a separate CSS file
import { FaCheckCircle } from "react-icons/fa";
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PaymentForm from '../payment/PaymentForm';
import CustomLoading from '../_customloading/CustomLoading';
const PaymentModal = ({ closeModal, carId }) => {
  const [clientToken, setClientToken] = useState(null);
  const [paypalOpen, setPaypalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/checkout/dcv/token`, {
        method: "POST",
      });
      const { client_token } = await response.json();
      setClientToken(client_token);
    })();
  }, []);

  const handlePaypalOpen = () => {
    setPaypalOpen(true);
  }


  const initialOptions = {
    "client-id": process.env.REACT_APP_CLIENT_API_PAYPAL,
    "enable-funding": "",
    "disable-funding": "sepa,bancontact,giropay,sofort,ideal,mybank,p24,eps,blik,trustly,venmo,paylater",
    "data-sdk-integration-source": "integrationbuilder_ac",
    dataClientToken: clientToken,
    components: "hosted-fields,buttons",
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 relative">
        {(paypalOpen && clientToken) ? (
          <PayPalScriptProvider options={initialOptions}>
            <PaymentForm setPaypal={setPaypalOpen} />
          </PayPalScriptProvider>
        ) : (
          <div className="payment-modal-content">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold text-gray-800">Payment Details</h2>
              <IoIosClose
                className="text-3xl text-gray-500 hover:text-gray-700 cursor-pointer"
                onClick={closeModal}
              />
            </div>
            <div className="service-description bg-green-50 border border-green-200 p-6 rounded-lg mb-6">
              <h3 className="text-xl font-semibold text-green-800 mb-4">Our Service Offer:</h3>
              <div className="grid grid-cols-2 gap-y-4">
                <div className="flex items-center gap-2 text-gray-700">
                  <FaCheckCircle size="20" className="text-green-500" />
                  <span>Hide Vehicle from Google</span>
                </div>
                <div className="flex items-center gap-2 text-gray-700">
                  <FaCheckCircle size="20" className="text-green-500" />
                  <span>Hide Images from Google</span>
                </div>
                <div className="flex items-center gap-2 text-gray-700">
                  <FaCheckCircle size="20" className="text-green-500" />
                  <span>Hide Vehicle from Damagedcarvin</span>
                </div>
                <div className="flex items-center gap-2 text-gray-700">
                  <FaCheckCircle size="20" className="text-green-500" />
                  <span>Hide Images from Damagedcarvin</span>
                </div>
              </div>
              <p className="mt-6 text-lg font-bold text-gray-800">Total Amount: $49.99</p>
            </div>
            <button
              onClick={handlePaypalOpen}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 rounded-lg transition duration-300 ease-in-out"
            >
              Pay Now
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentModal;