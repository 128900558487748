import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { linkClick } from '../../utils/link-click';
import { FaChevronRight } from 'react-icons/fa'; // Importing a right arrow icon from react-icons

const Breadcrumbs = ({ crumbs }) => {
  return (
    <nav aria-label="breadcrumb" className="bread-crumbs-list w-full py-4 px-6 flex items-center bg-gray-50 shadow-sm rounded-lg text-xs md:text-sm text-gray-500">
      <ol className="flex items-center space-x-2">
        {crumbs.map((crumb, index) => {
          const isLast = index === crumbs.length - 1;

          return (
            <Fragment key={index}>
              {isLast ? (
                <li className="flex items-center" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                  <span itemProp="name" className="font-semibold bg-blue-100 text-blue-600 px-2 py-1 rounded-md">
                    {crumb.label}
                  </span>
                  <meta itemProp="position" content={index} />
                </li>
              ) : (
                <>
                  <Link
                    to={crumb.path}
                    itemProp="itemListElement"
                    itemScope
                    itemType="http://schema.org/ListItem"
                    className="flex items-center space-x-1 hover:underline text-gray-700"
                    onClick={(e) => {
                      e.preventDefault();
                      linkClick(crumb.path);
                    }}
                  >
                    <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded-md font-medium">{crumb.label}</span>
                  </Link>
                  <meta itemProp="position" content={index} />
                  <span className="text-gray-400">
                    <FaChevronRight size={12} /> {/* Icon between breadcrumbs */}
                  </span>
                </>
              )}
            </Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
