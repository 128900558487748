import React from 'react';
import { Link } from 'react-router-dom';
import { MdTranslate } from "react-icons/md";
import { Helmet } from 'react-helmet-async';
const Terms = () => {
  return (
    <div className=" w-full min-h-screen flex flex-col items-center justify-center text-white bg-gray-900 p-10 relative overflow-hidden pt-20 md:pt-2">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {/* Decorative Elements */}
      <div className="absolute inset-0 bg-gradient-to-r from-purple-600 via-pink-600 to-red-600 opacity-30"></div>

      <div className="relative z-10 text-white w-full max-w-5xl">
        <span className='text-2xl'>Terms and Conditions</span>
        <p className="text-lg">
          DamagedCarVin is a privately held company that conducts business worldwide under the official name DamagedCarVin Ltd.
          DamagedCarVin is the formal title of the legal entity and has the corresponding website: damagedcarvin.com.
        </p>
        <p>
          Car or Vehicle is an automobile with its specific Vehicle Identification Number (VIN), specifications, and details listed on the DamagedCarVin platform.
          Terms and Conditions is the current document outlining the terms and conditions.
        </p>
        <p>The website, an online platform owned by DamagedCarVin, is governed by a specific set of rules detailed in the Terms and Conditions and is used for digital services.</p>
        <p>DamagedCarVin Services offers electronic services through its website, https://damagedcarvin.com, as outlined in this document.</p>
        <p>User is any physical entity utilizing the services of DamagedCarVin through the website and following the terms of the current document.</p>
        <p>Customer is a business or individual using paid services (such as VIN removal services)</p>

        <div className="mb-6">
          <span className="mb-4">Rules and Conditions</span>
          <p>
            The rights and responsibilities of users, customers, and website owners are outlined in The Terms and Conditions (this document) which can be found digitally on DamagedCarVin’s official website: https://damagedcarvin.com.
            Prior to utilizing the Website, the User is required to review and accept the Terms and Conditions.
            The DamagedCarVin website, found at https://damagedcarvin.com, serves anyone interested in DamagedCarVin’s services.
          </p>
        </div>
        <div className="mb-6">
          <span className=" mb-4">Online Services on the Website</span>

          <p> CheckDamagedCarVinedVin offers services on its designated website that consist of: </p>
          <ul className="mb-4">
            <li>Obtaining precise information and specifics regarding automobiles.</li>
            <li>Getting the VIN code and visual information of the vehicles.</li>
            <li>Granting users the option to request deletion of VIN information.</li>
            <li>Giving users the option to request updates on any information regarding specific vehicles.</li>
          </ul>
          <p> No separate agreement is necessary to carry out the services mentioned by the Users or other involved parties.</p>
        </div>
        <div className="mb-6">
          <span>Car or Vehicle</span>
          DamagedCarVin cannot assure the accuracy of vehicle details on the site, as it depends on official sources for data updates and verification, explicitly stating that it is not responsible for any potential inaccuracies.
          <p>  Incorrect mileage reading for a specific vehicle.</p>
          <p>  State of the vehicle's condition.</p>
          <p>  The current appearance of the car.</p>
          <p>  Details of vehicle ownership.</p>
          <p>  Details of pricing for vehicles.</p>
        </div>
        <div className="mb-6">
          <span>Terms and Conditions of Service</span>
          <p>
            The services are accessible to any User who is interested and are offered for free (except for the VIN removal service). In order to access services on the DamagedCarVin website, users need to meet specific technical requirements.
          </p>
          <p> The user must have an electronic device that allows:</p>
          <p> Access to the Internet</p>
          <p> Investigating different online platforms</p>
          <p> Communication through electronic mail</p>
        </div>
      </div>
    </div >
  );
};

export default Terms;
