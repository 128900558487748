import React from 'react';

const Contact = () => {
  return (
    <div className="relative min-h-screen bg-gradient-to-b from-indigo-600 via-purple-600 to-pink-600 flex items-center justify-center p-8">
      {/* Decorative Background Elements */}
      <div className="absolute top-0 left-0 w-1/3 h-full bg-pink-400 bg-opacity-30 transform rotate-45 -translate-y-1/3 -translate-x-1/3 filter blur-xl"></div>
      <div className="absolute bottom-0 right-0 w-1/3 h-full bg-indigo-400 bg-opacity-30 transform -rotate-45 translate-y-1/3 translate-x-1/3 filter blur-xl"></div>

      {/* Contact Info Card */}
      <div className="relative z-10 max-w-md w-full bg-white bg-opacity-20 backdrop-blur-lg rounded-3xl shadow-2xl p-8">
        <h1 className="text-3xl font-extrabold text-white mb-6 text-center tracking-wide animate-pulse">
          Get in Touch
        </h1>

        {/* Contact Details */}
        <div className="space-y-6">
          <div className="flex items-center space-x-4 text-white">
            <span className="text-lg font-semibold">Email:</span>
            <span className="text-base font-light hover:underline transition duration-300 cursor-pointer">
              damagedcarvin@gmail.com
            </span>
          </div>
          <div className="flex items-center space-x-4 text-white">
            <span className="text-lg font-semibold">Telegram:</span>
            <span className="text-base font-light hover:underline transition duration-300 cursor-pointer">

              <a
                href="https://t.me/damagedcarvininfo"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-white"
              >
                @damagedcarvininfo
              </a>
            </span>
          </div>
          <div className="flex items-start space-x-4 text-white">
            <span className="text-lg font-semibold whitespace-nowrap">Company Address:</span>
            <span className="text-base font-light hover:underline transition duration-300 cursor-pointer">
              1617 John F Kennedy Blvd, Philadelphia, PA 19103, USA
            </span>
          </div>
          <div className="flex items-center space-x-4 text-white">
            <span className="text-lg font-semibold">Business Center:</span>
            <span className="text-base font-light hover:underline transition duration-300 cursor-pointer">
              Four Penn Center
            </span>
          </div>
        </div>

        {/* CTA Button */}
        <div className="mt-8 flex justify-center">
          <a
            href="https://t.me/damagedcarvininfo"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-8 py-3 bg-white text-indigo-600 font-semibold text-lg rounded-full shadow-lg hover:bg-indigo-50 transition duration-300"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
