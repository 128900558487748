import React from "react";

const Business = () => {
  const PA = "https://allvinhistory-bucket.s3.amazonaws.com/assets/admin";

  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center bg-gray-900 p-10 relative overflow-hidden pt-20 md:pt-2">
      {/* Decorative Elements */}
      <div className="absolute inset-0 bg-gradient-to-r from-purple-600 via-pink-600 to-red-600 opacity-30"></div>
      <div className="absolute top-0 left-0 w-full h-full bg-cover bg-center mix-blend-overlay" style={{ backgroundImage: `url(${PA}/background-image.jpg)` }}></div>

      {/* Content Container */}
      <div className="relative z-10 text-white w-full max-w-5xl">
        <div className="flex flex-col gap-12 items-center text-lg md:text-xl">
          <h2 className="text-4xl font-bold tracking-wider text-center mb-8">
            Welcome to damagedcarvin.com!
          </h2>

          <div className="flex flex-col md:flex-row justify-between gap-10 md:gap-20">
            <p className="md:w-[45%] leading-relaxed">
              DamagedCarVin is your #1 vehicle hub with dozens of professionals at your disposal.
              The customers are our top priority and we do everything for your satisfaction.
              DamagedCarVin is an informative website that covers used cars for sale online.
              Each product page comes with a detailed description section that covers vehicles’ history records, VIN code and information, past accidents, reports on damages and maintenance, ownership records, and much more.
            </p>
            <p className="md:w-[45%] leading-relaxed">
              Why should you choose DamagedCarVin website for your research?
              <ul className="list-disc list-inside mt-4">
                <li>Comprehensive data for car buyers</li>
                <li>Valuation insights for vehicle sellers</li>
                <li>Images and media depicting damaged parts of the vehicle</li>
                <li>VIN codes and lot information</li>
                <li>Details of the car and manufacturer</li>
              </ul>
            </p>
          </div>

          <div className="w-full flex justify-center">
            <p className="md:w-[60%] leading-relaxed text-center">
              Let us know if you have any inquiries regarding our products and services or additional requests.
              Drop us a message and DamagedCarVin customer service will respond to your inquiry in two working days.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
